$sidebar-width: 300px;
html {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}

iframe{
  width: 100%;
  padding: 2rem;
  background-color: grey;
}
.appBody {
  display: flex;

  .content:not(:last-child) {
    margin-bottom: 0px !important;
  }
  .componentList {
    overflow-x: hidden;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    background-color: #f4f7fb;
    padding: 1.5rem;

    .component-row{
      & + .component-row{
        margin-top: 1.5rem;
      }
    }

    .elementTitle {
      font-size: 2rem;
      font-weight: 700;
      padding: 0 0.7rem;
      padding-top: 50px;
      &:first-child {
        padding-top: 0px;
      }
    }

    .navbar {
      margin: 10px 0.7rem;
      .sgds-container {
        padding: inherit;
        .navbar-menu {
          padding: inherit;
          .navbar-item.has-dropdown {
            padding: inherit;
          }
        }
      }
    }
    .button-row {
      padding: 10px 0.7rem;
      .sgds-button {
        margin-right: 15px;
      }
    }
    .sgds-breadcrumb li {
      margin-top: 0px !important;
    }
    blockquote {
      ul {
        list-style: inherit;
      }
    }
    .slider {
      & div {
        padding: 0 !important;
      }
    }
    .sgds-hero {
      .sgds-hero-body {
        width: auto !important;
      }
    }
  }
  .componentList::-webkit-scrollbar {
    display: none;
  }
  .template {
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1.5rem;
    background: lightslategrey;
  }
  .colorPickerContainer{
    width: 100%;
    display:grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
    
  }
  .sideBar {
    order: 1;
    flex: 0 0 auto;
    height: 100vh;
    border-left: 1px solid #dfe3e6;
    padding: 0px;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;

    &.left{
      order:0;
    }

    .elementList {
      height: 100%;
      width: 140px;
      padding: 10px 5px;
      overflow-y: auto;
      // display: none;
      .listItem {
        padding: 5px;
        a {
          color: inherit;
          padding: 3px;
        }
      }
    }
    .elements {
      background-color: #f9f9f9;
      width: 260px;
      overflow-y: auto;
      .buttonGroup {
        padding: 5px 10px;
        .colorpickerCon{
          padding: 10px 0 10px;
          &:first-child{
            padding-top: 0;
          }
          &:last-child{
            padding-bottom: 20px;
          }
        }

        .colorPicker {
          .color-preview {
            width: 33px;
            height: 33px;
            border-radius: 9999px;
            z-index: 1;
          }
        }
        .inputTitle:first-child {
          margin-top: 0px;
        }
        .inputTitle {
          margin-top: 15px;
        }
        .inputField {
          padding: 0.5rem;
          border: 1px solid #d9d9d9;
          border-radius: 0.5rem;
        }
        .resetBtn {
          color: #1890ff;
          font-size: 10px;
          margin-top: 3px;
        }
        .color {
          text-align: left;
          padding: 0.5rem;
          border: 1px solid #d9d9d9;
          border-radius: 0.5rem;
        }
      }
    }
    & label {
      padding: 0px;
      input {
        margin-left: 5px;
      }
    }
  }
  .sideBar::-webkit-scrollbar {
    display: none;
  }
}
.appBody::-webkit-scrollbar {
  display: none;
}

.sgds-navbar-brand{
  .sgds-navbar-item,
  .navbar-item{
    img{
      max-height: 80px;
      width: 200px;
      height: 80px;
    }
  }

}